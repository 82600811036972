<template>
  <div>
    <van-form @submit="onSubmit" scroll-to-error>
      <van-cell-group title="拜访信息" inset>

        <van-field
            readonly
            name="code"
            v-model="code"
            label="预约编号"
            placeholder="自动生成"
        ></van-field>
        <Picker
            name="interviewedUser.id"
            label="受访人员"
            placeholder="请选择受访人员"
            displayField="nameCode"
            :required=true
            v-model:value="this.$data['interviewedUser.id']"
            :defConfig="{
            portName: 'system.org.User.getUsers',
              data: {'userType.code': 'USER_TYPE_STAFF'},
           }"
            :rules="[{ required: true, message: '请选择受访人员' }]"
            :canSearch=true
            search-param="name"
            @onConfirm="onUserConfirm"
            auto-search
        ></Picker>
        <Picker
            name="interviewedDepart.id"
            label="受访部门"
            placeholder="请选择受访部门"
            displayField="cnName"
            :readonly="true"
            v-model:value="this.$data['interviewedDepart.id']"
            :defConfig="{
            portName: 'touch.system.org.Depart.getDeparts',
            data: {'companyTypeCodes': ['SelfCompany','BranchCompany']},
           }"
            ref="department"
            :canSearch=false
        ></Picker>
        <van-field
            readonly
            name="interviewedWorkNum"
            v-model="interviewedWorkNum"
            label="工号"
        ></van-field>

        <DatePicker
            name="planTime"
            label="访问时间"
            v-model:value="planTime"
            :min-date="minDate"
            :required=true
            :rules="[{ required: true, message: '请选择访问时间' }]"
        >
        </DatePicker>


        <DatetimePicker
            name="startTime"
            v-model:value="startTime"
            type="time"
            label="预计拜访时间"
        />
        <DatetimePicker
            name="endTime"
            v-model:value="endTime"
            type="time"
            label="预计结束时间"
        />

        <Picker
            name="visitReasonDic.id"
            label="拜访事由"
            placeholder="请选择拜访事由"
            displayField="name"
            :required=true
            v-model:value="this.$data['visitReasonDic.id']"
            :defConfig="{
              portName: 'system.foundation.Dic.getChildDicsByCode',
              data: {code: 'VISIT_REASON', filterCodes: ['ZYSG']},
            }"
            :rules="[{ required: true, message: '请选择拜访事由' }]"
            :canSearch=false
        ></Picker>

        <van-field
            v-model="visitReason"
            name="visitReason"
            rows="2"
            autosize
            label="事由详情"
            type="textarea"
            placeholder="事由详情"
            show-word-limit
            required
            :rules="[{ required: true, message: '请填写事由详情' }]"
        ></van-field>
        <van-field name="carryCar" label="是否驾车入园"
                   :required=true
                   label-width="32vw"
                   :rules="[{ required: true, message: '请选择是否驾车入园' }]"
                   v-model:value="isCarryCar"
        >
          <template #input>
            <van-radio-group v-model="isCarryCar" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <Picker
            v-if="carryCar"
            name="carNum"
            label="车牌号"
            placeholder="请选择车辆"
            displayField="carNum"
            valueField="carNum"
            v-model:value="carNum"
            :defConfig="{
              portName:'osp.visitor.VisitorCarEntity.getVisitorCars',
                       data: {'visitor.id': this.$data['visitor.id'], 'typeDic.code': 'VISITOR_CAR_TYPE_NOT_TRUCK'},

            }"
            :rules="[{ required: true, message: '请选择车辆' }]"
            :canSearch=false
            :defSelectValCallback="function(raw) {
              if (raw.default){
                return true;
              }
            }"
            @onConfirm="onCarConfirm"
        ></Picker>
        <van-field
            v-if="carryCar"
            readonly
            name="carBrand"
            v-model="carBrand"
            label="车辆型号"
        ></van-field>
        <van-field name="remarkAttach" label="附件">
          <template #input>
            <van-uploader name="remarkAttach" v-model="remarkAttach"
                          :before-read="beforeRead"
                          :after-read="afterRead"
                          max-count="1"></van-uploader>
          </template>
        </van-field>

      </van-cell-group>
      <van-cell-group title="人员信息" icon="friends-o" inset>
        <van-field
            v-model="name"
            name="name"
            label="来访人员"
            placeholder="来访人员"
            readonly
            required
            :rules="[{ required: true, message: '请填写来访人员' }]"
        ></van-field>
        <van-field
            v-model="tel"
            name="tel"
            label="电话号码"
            placeholder="电话号码"
        ></van-field>
        <van-field
            v-model="unit"
            name="unit"
            readonly
            label="所属单位"
            placeholder="所属单位"
        ></van-field>

        <van-field
            v-model="originArea"
            name="originArea"
            label="来源地区"
            placeholder="来源地区"
            required
            :rules="[{ required: true, message: '请填写来源地区' }]"
        ></van-field>

        <van-field name="faceIDAttach"
                   label="人脸"
                   required
                   :rules="[{ required: true, message: '请上传人脸照片' }]"
        >
          <template #input>
            <van-uploader name="faceIDAttach" v-model="faceIDAttach"
                          :before-read="beforeRead"
                          max-size="200*1024"
                          @oversize="onOversize"
                          :after-read="afterRead"
                          max-count="1"></van-uploader>
          </template>
        </van-field>
        <van-field name="healthCodeAttach"
                   label="健康码"
        >
          <template #input>
            <van-uploader name="healthCodeAttach" v-model="healthCodeAttach"
                          :before-read="beforeRead"
                          :after-read="afterRead"
                          max-count="1"></van-uploader>
          </template>
        </van-field>
      </van-cell-group>

      <div style="margin: 4.26667vw">
        <van-button round block type="primary" native-type="submit" :disabled="btnDisabled">
          保存
        </van-button>
      </div>
      <div style="margin: 4.26667vw">
        <van-button round block type="primary" @click="submitApprove" :disabled="btnDisabled">
          提交预约
        </van-button>
      </div>
    </van-form>
  </div>
  <van-dialog v-model:show="showDialog" title="HSE守则">
    <div style="margin: 4.8vw;height: 106.66667vw;overflow: auto">
      <p style="white-space: pre-line;">{{hseText}}</p>
    </div>
  </van-dialog>

</template>

<script>
import {Toast} from "vant";
import {ref} from "vue";
import _ from "lodash";
import Picker from "../../components/Picker";
import DatePicker from "../../components/DatePicker";
import Compressor from "compressorjs";
import DatetimePicker from "../../components/DatetimePicker";


export default {
  components: {Picker, DatePicker, DatetimePicker},
  name: "VisitorReserve",
  data() {
    return {
      id: '',
      'visitor.id': '',
      code: '',
      'interviewedDepart.id': '',
      'interviewedUser.id': '',
      isCarryCar: 'true',
      planTime: "",
      interviewedWorkNum: "",
      'visitReasonDic.id': '',
      visitReason: "",
      name: "",
      tel: "",
      unit: "",
      carNum: "",
      originArea: "",
      carBrand: "",
      startTime: '10:00',
      endTime: '12:00',
      faceIDAttach: [],
      healthCodeAttach: [],
      remarkAttach: [],
      btnDisabled: false,
      showDialog: false,
      minDate: new Date(),
      hseText: ''
    };
  },
  computed:{
    carryCar: function () {
      return this.isCarryCar == 'true'
    }
  },
  methods: {
    loadData() {
      var me = this;
      var id = me.$route.params.id;
      if (!_.isEmpty(id)) {
        me.btnDisabled = true;
        var option = {
          portName: 'osp.visitor.VisitorReserve.getVisitorReserve',
          data: {id: id},
          needLoading: false,
          successCallback: function (data) {
            var entities = data.entities;
            if (!_.isEmpty(entities)) {
              var entity = entities[0];
              var _data = me.$data;
              for (var attrName in entity) {
                if (_data[attrName] != undefined) {
                  _data[attrName] = entity[attrName];
                }
              }
              var planTime = entity.planTime;
              if (!_.isEmpty(planTime)) {
                planTime = planTime.replace(/-/g, '/');
              }
              _data.planTime = _.isEmpty(planTime) ? "" : new Date(planTime).pattern('yyyy-MM-dd').toString();
              if (entity['statusDic.code'] !== 'VISITOR_RESERVE_STATUS_DEFAULT' && entity['statusDic.code'] !== 'VISITOR_RESERVE_STATUS_REJECT') {
                me.btnDisabled = true;
              } else {
                me.btnDisabled = false;
              }
            }
          }
        }
        this.$sapi.callPort(option);
      } else {
        me.showDialog = true;
        me.btnDisabled = false;
        var visitor = this.$sapi.storage.getItemJson('visitor');
        this['visitor.id'] = visitor.id;
        this.name = visitor.name;
        this.tel = visitor.tel;
        this.unit = visitor.unit;
      }
    },
    onSubmit(values) {
      values['id'] = this.id;
      values['visitor.id'] = this['visitor.id'];
      var option = {
        portName: 'osp.visitor.VisitorReserve.saveVisitorReserve',
        data: values,
        needLoading: false,
        successCallback: (data) => {
          this.id = data.data.id;
          this.$notify({type: 'success', message: '保存成功'});
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    submitApprove() {
      var me = this;
      var id = me.id;
      if (_.isEmpty(id)) {
        Toast.fail('请先保存');
        return;
      }
      var option = {
        portName: 'osp.visitor.VisitorReserve.submitVisitorReserve',
        data: {ids: [id]},
        needLoading: false,
        successCallback: (data) => {
          me.id = data.data.id;
          this.$dialog.alert({
            title: '',
            message: '提交成功',
          }).then(() => {
            me.$router.go(-1);
          });
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    beforePickUser() {
      var me = this;
      var id = me['interviewedDepart.id'];
      if (_.isEmpty(id)) {
        Toast.fail('请先选择部门');
        return false;
      } else {
        return true
      }
    },
    onUserConfirm(value) {
      this.interviewedWorkNum = value.workNumber;
      this.$refs.department.setValue(value.departID);
    },
    onCarConfirm(value) {
      this.carBrand = value.brand;
    },
    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 75, true);
    },
    onOversize:(file) => {
      Toast('文件大小不能超过 200kb');
    },
    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
  },
  created: function () {
    var me = this;
    this.loadData();
    //this.loadHseText();
  },
  mounted() {
  },
  watch: {
    carryCar: function (val) {
      if (!val) {
        this.carNum = '';
        this.carBrand = '';
      }
    }
  }
};
</script>

<style>
</style>